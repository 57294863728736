/** @format */

import example_1_before from 'assets/images/examples/after_6.jpg';
import example_1_after from 'assets/images/examples/before_6.jpg';
import example_2_before from 'assets/images/examples/after_5.jpg';
import example_2_after from 'assets/images/examples/before_5.jpg';
import example_3_before from 'assets/images/examples/after_3.jpg';
import example_3_after from 'assets/images/examples/before_3.jpg';
import example_7_after from 'assets/images/examples/before_7.jpg';
import example_7_before from 'assets/images/examples/after_7.jpg';
import example_8_after from 'assets/images/examples/before_8.jpg';
import example_8_before from 'assets/images/examples/after_8.jpg';

export const portfolioCases = [
  {
    imageBefore: example_1_before,
    imageAfter: example_1_after,
  },
  {
    imageBefore: example_2_before,
    imageAfter: example_2_after,
  },
  {
    imageBefore: example_3_before,
    imageAfter: example_3_after,
  },
  {
    imageBefore: example_7_before,
    imageAfter: example_7_after,
  },
  {
    imageBefore: example_8_before,
    imageAfter: example_8_after,
  },
];
