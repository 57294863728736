/** @format */

export const ABOUTME_TITLE = 'Обо мне';
export const ABOUTME_TEXT =
  'Меня зовут Екатерина, и я специализируюсь как стилист, ориентированный на типажи внешности. Моя цель заключается в том, чтобы помочь каждому человеку подчеркнуть свою индивидуальность и уникальный стиль. Я помогаю людям выразить себя через грамотный выбор одежды, причесок и аксессуаров, исходя из их индивидуальных черт внешности.';
export const MY_SERVICES = 'Мои услуги';
export const PORTFOLIO_TITLE = 'Портфолио';
export const CONTACT_TITLE = 'Задай мне вопрос.';
export const TESTIMONIALS_TITLE = 'Отзывы';
export const INTRODUCING = {
  firstName: 'екатерина',
  lastName: 'янушевская',
  subtitle: 'твой персональный стилист',
};

export const SERVICES_TAG = 'services';
export const ABOUT_TAG = 'about';
export const PORTFOLIO_TAG = 'portfolio';
export const CONTACT_TAG = 'contact';
export const TESTIMONIALS_TAG = 'testimonials';

//Buttons
export const SEND = 'Отправить';
export const SENDING = 'Отправка';
export const SIGN_UP = 'Войти';

export const SERVICE_LOOKBOOK = [
  'Определение Вашего типажа внешности',
  'Создания подробного Lookbook (инструкции)',
];
export const SERVICE_PORTRAIT = [
  'Определение Вашего типажа',
  'Подробное описание с наглядными примерами по оформлению Вашей портретной зоны (стрижка, макияж, очки, вырезы в одежде, лацканы, пример принтов).',
];
export const SERVICE_COMBO = [
  'Услуга, которая включает в себя определение типажа и колорита внешности',
  'Lookbook + Colorbook',
];
export const SERVICE_COLORBOOK = [
  'Определение Вашего природного колорита внешности',
  'Создание Colorbook',
];
export const SERVICE_COLOR = ['Разбор внешности и составления гайда'];
export const SERVICE_WARDROBE = [
  'Разбор вашего гардеробаб',
  'Даю советы то, какие вещи подходит и не подходит под ваш типаж',
];

export const TESTI_ClIENT1 =
  'Моя встреча со стилистом была двоякой. С одной стороны, она предложила несколько интересных идей, которые я бы сам никогда не придумал. С другой стороны, некоторые из её рекомендаций не подошли моему вкусу и стилю. Возможно, это было небольшим недопониманием. В итоге, в некоторых нарядах я себя чувствовал не совсем комфортно, но все равно благодарен за её старания.';
export const TESTI_ClIENT2 =
  'Этот стилист — настоящий профессионал своего дела! Я был приятно удивлен, как она смогла учесть все мои пожелания и предоставила мне множество вариантов для обновления гардероба. Она не только помогла мне с выбором одежды, которая идеально подходит моей фигуре, но и дала ценные советы по аксессуарам и макияжу. Результаты превзошли все мои ожидания! Большое спасибо, обязательно вернусь к вам снова!';
export const TESTI_ClIENT3 =
  'Я просто в восторге от работы стилиста! Она смогла превратить мою скучную повседневную одежду в настоящие модные образы. Её чувство стиля просто поражает, и она отлично понимает, как подчеркнуть индивидуальность каждого клиента. С ней я почувствовала себя уверенной и привлекательной. Большое спасибо за прекрасную работу!';

export const EXAMPLES_PHRASES = 'Знание типажа помогает обрести гармонию  и уверенность в себе.';
