/** @format */

import { WIDTH_S } from "globalStyles";
import styled from "styled-components";

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: inherit;
	min-width: ${WIDTH_S};
`;
